export default function debounce(callback, duration = 100) {
  let timer
  return function(event) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(callback, duration, event)
  }
}

export const timers = {}
export function debounceByKey(key, callback, duration = 100) {
  return function(event) {
    if (timers[key]) clearTimeout(timers[key])
    return new Promise((resolve) => {
      timers[key] = setTimeout((event) => {
        resolve(callback(event))
      }, duration, event)
    })
  }
}

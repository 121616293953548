import { DevLogs } from './devLogs'
import { EntityName, getEntityObject, getFieldTranslationKey } from './entities'
import { getFirstString } from './getFirstOfType'
import { getNameKeyOfEntity } from './history'
import { isNonNullObject } from './isObject'
import { toCamel } from './stringUtils'

export async function safeFetch(...args: Parameters<typeof fetch>): Promise<Response> {
  const resp = await fetch(...args)

  const contentType = resp.headers.get('content-type')

  if (contentType && contentType.indexOf('application/json') === -1) {
    throw new InvalidAPIFormatError(resp)
  }

  return resp
}

export type SafeFetchErrorResponse = {
  isSuccess: false,
  result: {
    name: string,
    message: string,
  }
}

type SafeFetchJobResponse = {
  isSuccess: true,
  result: {
    isTimeout: true,
    jobUUID: string,
    cid: string,
  }
}

type SafeFetchSuccessResponse<T> = {
  isSuccess: true,
  result: T,
}

export type SafeFetchResponse<T = any, IsArray extends boolean = true> =
  IsArray extends true ?
    SafeFetchErrorResponse | SafeFetchJobResponse | SafeFetchSuccessResponse<T[]> :
    SafeFetchErrorResponse | SafeFetchJobResponse | SafeFetchSuccessResponse<T>

/**
 * @param  {...any} args
 * @return {Promise<SafeFetchResponse>}
 */
export async function safeFetchJson<T, IsArray extends boolean = true>(
  ...args: Parameters<typeof fetch>
): Promise<SafeFetchResponse<T, IsArray>> {
  try {
    const response = await (await safeFetch(...args)).json()

    return response.isSuccess ? response : parseError(response)
  } catch (e) {
    return parseError(e)
  }
}

export function isJob<T extends object = {}, IsArray extends boolean = true>(
  result: SafeFetchResponse<T, IsArray>['result'],
): result is SafeFetchJobResponse['result'] {
  return isNonNullObject(result) && 'jobUUID' in result
}

export function isError(result: SafeFetchResponse['result']): result is SafeFetchErrorResponse['result'] {
  return isNonNullObject(result) && 'message' in result
}

export function parsedResultOnSucessOrEmtpy(
  response: SafeFetchResponse,
  parser: (value: any, mapData?: Record<string, any>) => any,
  mapData: Record<string, any> = {},
): any[] {
  /**
   * If the response is not a success, return an empty array
   */
  if (!response.isSuccess) return []

  /**
   * If the response is a job, return an empty array
   */
  if (!Array.isArray(response.result)) return []

  return response.result.map((data) => parser(data, mapData))
}

type ParsedErrorType = {
  code: 8888,
  result: {
    name: string,
    message: string,
  },
  isSuccess: false,
}

export function parseError(error: any): ParsedErrorType {
  return {
    code: 8888,
    result: error.result || {
      name: getFirstString(error.name, 'Error'),
      message: getFirstString(error, error.message, 'Unknown error'),
    },
    isSuccess: false,
  }
}

export class InvalidAPIFormatError extends Error {
  private response: Response

  constructor(response) {
    super(`The response is not of the correct type. Status: ${response.status} ${response.statusText}`)
    this.response = response
    this.name = 'InvalidAPIFormatError'
  }
}

type ConflictedEntityType = {
  entityName: EntityName,
  summary: string|string[],
  mode?: string,
  errorCode?: string,
  conflicts: {
    field: string,
    data: Record<string, any>,
    title: string,
    fieldKey: string|string[],
  }[]
}

type ErrorType = {
  entityNameKey: string|string[],
  title: string,
  data: Record<string, any>,
  conflictedEntities: ConflictedEntityType[],
}

type ErrorObjectType = Record<string, ErrorType>

type FormattedDepdendenciesErrorType = {
  errors: ErrorType[],
  namespaces: string[],
}

export function parseDependenciesError(error: ErrorObjectType, entityName: EntityName):
  FormattedDepdendenciesErrorType {
  const entityObject = getEntityObject(entityName)

  if (!entityObject) {
    throw new Error(`[entityObject] Entity ${entityName} not found`)
  }

  const entities = new Set<EntityName>()

  entities.add(entityName)

  const formattedError: ErrorType[] =
    Object
      .values(error)
      .map<ErrorType>((error) => {
        const { data, conflictedEntities } = error

        const title = entityObject.getTitle(entityObject.parser(data))
        if (!title) {
          DevLogs.warn(`Missing title for entity '${entityName}' with data:`, data)
        }

        const formattedError = {
          entityNameKey: getNameKeyOfEntity(entityName, { entityName: true }),
          title,
          data,
          conflictedEntities: conflictedEntities.map((entity) => {
            const { entityName, conflicts, mode, errorCode } = entity

            entities.add(entityName)

            const conflictedEntityObject = getEntityObject(entityName)

            if (!conflictedEntityObject) {
              throw new Error(`[conflicEntityObject] Entity ${entityName} not found`)
            }

            return {
              entityName,
              summary: getNameKeyOfEntity(entityName),
              mode,
              errorCode,
              conflicts: conflicts.map((conflict) => {
                const { field: fieldKey, data } = conflict

                const field = toCamel(fieldKey)
                const title = conflictedEntityObject.getTitle(conflictedEntityObject.parser(data)) || data?.title

                return {
                  field: field,
                  data,
                  title,
                  fieldKey: getFieldTranslationKey(conflictedEntityObject, field),
                }
              }),
            }
          }),
        }

        return formattedError
      })

  const namespaces = Array
    .from(entities)
    .reduce<Set<string>>((acc, entityName) => {
      const entityObject = getEntityObject(entityName)

      if (!entityObject) {
        throw new Error(`[entityObject] Entity ${entityName} not found`)
      }

      acc.add(entityObject.translationFile)

      entityObject.relationTranslationFiles.forEach((file) => acc.add(file))

      return acc
    }, new Set())

  return {
    errors: formattedError,
    namespaces: Array.from(namespaces),
  }
}

export const objectToInsertData = (data: Record<string, any>) => {
  return Object
    .keys(data)
    .reduce((acc, key) => {
      acc.push({ column: key, value: data[key] })

      return acc
    }, [])
}

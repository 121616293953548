import React, { forwardRef, useMemo } from 'react'

type ErrorWrapperProps = {
  active?: boolean
  message?: string
  children: React.ReactNode
}

export type ErrorWrapperRef = {}

const ErrorWrapper = forwardRef<ErrorWrapperRef, ErrorWrapperProps>(({ active, message, children }, ref) => {
  const _message = useMemo(() => message || '', [message])
  return (
    <>
      {children}
      {!!_message && (
        <div
          className="a-error"
          data-is-error={!!active}
        >
          {_message}
        </div>
      )}
    </>
  )
})

ErrorWrapper.displayName = 'ErrorWrapper'
export default ErrorWrapper

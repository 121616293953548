export function isObject(value: any): value is object {
  return typeof value === 'object'
}

export function isNonNullObject(value: any): value is Record<any, unknown>|Array<any> {
  return isObject(value) && value !== null
}

export function isRecord(value: any): value is Record<any, unknown> {
  return isObject(value) && value !== null && !Array.isArray(value)
}

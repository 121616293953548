import { GetFields } from 'types/slices'
import { RecursiveKeys } from 'types/utils'

import { LineItemsForm } from 'reducers/smart-form/smartFormTypes'

import { testCondition } from './conditionHelper'
import { valueOrTrue } from './defaultValueHelper'
import { DevLogs } from './devLogs'
import { drillDownObject } from './drillDownObject'
import { toCamel, toSnake } from './stringUtils'

const nonNullableTypes = ['number', 'boolean']

export type ObjectToInsertDataReturnType = Array<{
  column: string;
  dataType?: string;
  value: string | number | null;
}>;

export type FormKeyDataToObjectReturnType = {
  [key: string]: string | number | null;
};

/**
 *
 * @param {*} object
 * @param {*} field
 * @param {*} options
 * @return {*}
 */
export function lookupInObject(
  object = {},
  field,
  options: {convertNumberToString?: boolean, fields?: {dbField: string}[]} = {},
) {
  if (!object || typeof(object) !== 'object') {
    object = {}
  }

  let value
  if (field && typeof(field) === 'object' && field.fieldName) {
    if (typeof(field.fromItem) === 'object' && field.fromItem.match && Array.isArray(object[field.fieldName])) {
      const item = object[field.fieldName].find((item) => {
        return testCondition(item, field.fromItem.match)
      })
      if (item) {
        value = lookupInObject(item, field.fromItem.field, options)
      }
    } else if (field.field && typeof(object[field.fieldName]) === 'object') {
      value = lookupInObject(object[field.fieldName], field.field, options)
    } else {
      value = object[field.fieldName]
    }
  } else if (typeof(field) === 'string') {
    value = object[field]
    if (!value && options.fields) {
      const formattedField = Object.keys(options.fields).find((key) => options.fields[key].dbField === field)
      value = object[formattedField]
    }
  }

  if (options.convertNumberToString && typeof(value) === 'number') {
    value = value.toString()
  }

  return value
}

function addValueToData(column, value, data, dataSetName = undefined, dataType = undefined, isAutoDate = true) {
  if (!data) {
    data = []
  }

  if (data.type === 'batch') {
    data.values.forEach((batchValues) => {
      addValueToData(column, value, batchValues, dataSetName)
    })
    return data
  }

  if (!data.some((obj) => obj.column === column) && typeof(value) !== 'undefined' && value !== null) {
    if (isAutoDate && column.toUpperCase().includes('DATE') && !['null', 'NULL'].includes(value)) {
      value = (new Date(value)).toUTCString()
    }
    if (Array.isArray(value) && !dataType) {
      dataType = 'enum'
    } else if (typeof value === 'object' && value !== null && !dataType) {
      dataType = 'json'
    }
    if (dataSetName) {
      data.push({ dataSetName, column, value, dataType })
    } else {
      data.push({ column, value, dataType })
    }
  }
  return data
}

/**
 * @param {*} object
 * @return {ObjectToInsertDataReturnType}
 */
export function objectToInsertData(object) {
  const data = []
  Object.keys(object).forEach(function(key) {
    addValueToData(key, object[key], data)
  })
  return data
}

export function buildDictionary<
  T extends Record<string, any>,
  K extends keyof T |(keyof T)[],
>(elements: T[], keys: K): Record<string, T> {
  return elements.reduce<Record<string, T>>((acc, element) => {
    const key = Array.isArray(keys) ?
      (keys as (keyof T)[]).map((k) => String(element[k])).join(';') :
      String(element[keys as keyof T])

    acc[key] = element
    return acc
  }, {})
}

export function buildAggregationDictionary(elements = [], keys) {
  return elements.reduce((acc, element) => {
    const key = Array.isArray(keys) ? keys.reduce((keyAcc, key) => {
      keyAcc.push(element[key])
      return keyAcc
    }, []).join(';') : element[keys]

    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(element)
    return acc
  }, {})
}

export function formDataToArray(
  formData = {},
  isDatabase?: boolean,
  onlyChanged?: boolean,
  includeId?: boolean,
  skipNonEditableField?: boolean,
  fieldsData?: any,
) {
  return Object.keys(formData)
    .map((id) => {
      return formKeyDataToObject(formData[id], {
        isDatabase,
        onlyChanged,
        includeId,
        id,
        skipNonEditableField,
        fieldsData: fieldsData,
        isDatabaseNull: false,
      })
    })
}

/**
 * @param {*} keyData
 * @param {*} options
 * @return {FormKeyDataToObjectReturnType}
 */
export function formKeyDataToObject(
  keyData = {},
  options: {
    isDatabase?: boolean,
    onlyChanged?: boolean,
    includeId?: boolean,
    isDatabaseNull?: boolean
    skipUpdateDbField?: boolean
    skipNullValues?: boolean
    skipNonEditableField?: boolean
    id?: string | number
    fieldsData?: GetFields<any, any, any, any, any, any>
    applyXor?: boolean
  } = {},
) {
  const isDatabase = valueOrTrue(options.isDatabase)
  const onlyChanged = valueOrTrue(options.onlyChanged)
  const includeId = valueOrTrue(options.includeId)
  const isDatabaseNull = valueOrTrue(options.isDatabaseNull)
  const skipUpdateDbField = !!options.skipUpdateDbField
  const skipNullValues = options.skipNullValues

  const skipNonEditableField = options.skipNonEditableField === true
  const fieldsData = options.fieldsData || {}
  const applyXor = valueOrTrue(options.applyXor)

  const object: any = {}
  if (includeId && options.id) {
    object.id = options.id
  }

  let keys = Object.keys(keyData)

  if (onlyChanged) {
    keys = keys.filter((key) => keyData[key].isChanged)
  }

  if (skipNonEditableField) {
    keys.forEach((key) => {
      if (!fieldsData[key]) {
        DevLogs.error('formKeyDataToObject', `Field \`${key}\` not found`, fieldsData, key)
      }

      if (!fieldsData[key].isEdit) {
        // DevLogs.warn('formKeyDataToObject', `Field \`${key}\` is not editable`, fieldsData, key)
      }
    })

    keys = keys.filter((key) => fieldsData[key]?.isEdit)
  }

  const keyToInterimKeyMap = {}
  keys.forEach((key) => {
    const field = fieldsData[key]
    const changedField = keyData[key]
    const dbField = changedField.dbField || key

    const interimKey = isDatabase ?
      (!skipUpdateDbField ? changedField.updateDbField || dbField : dbField) :
      key

    keyToInterimKeyMap[key] = interimKey

    if (field?.type === 'json' && field?.properties && field?.isParseProperties) {
      // Recursive to format json fields properties as well
      object[interimKey] = formKeyDataToObject(changedField.value, {
        ...options,
        fieldsData: field.properties,
        includeId: false,
      })
    } else {
      const databaseValue = nonNullableTypes.includes(typeof changedField.value) ?
        changedField.value :
        (changedField.value || (isDatabaseNull ? 'NULL' : null))

      let value = isDatabase ? databaseValue : changedField.value
      if (changedField.isNull) {
        value = null
      }
      if (skipNullValues && value === null && !changedField.includeNullValue) return

      object[interimKey] = value
    }
  })
  // If a field (fieldA) is mutually exclusive with another (fieldB), we remove fieldA if fieldB is present.
  // The field that needs to be removed if both are present is the one that has xor set in its definition.
  if (applyXor) {
    const fieldsWithXor = Object.keys(fieldsData).filter((key) => fieldsData[key]?.xor)
    fieldsWithXor?.forEach((key) => {
      const fieldInterimKey = keyToInterimKeyMap[key]
      const xorFieldInterimKey = keyToInterimKeyMap[fieldsData[key].xor]
      if (object[fieldInterimKey] && object[xorFieldInterimKey]) {
        delete object[fieldInterimKey]
      }
    })
  }

  return object
}

export type FormFieldData = {
  value: any,
  isChanged: boolean,
  dataSetName: string,
  dbField: string,
  isValid?: boolean,
}
export type DataToFormDataOptions = {
  forceIsChangedWhenNotEmpty?: boolean,
  defaultDataSetName?: string
}
const defaultOptions: DataToFormDataOptions = {
  forceIsChangedWhenNotEmpty: false,
  defaultDataSetName: '',
}
export function dataToFormData(
  data,
  fields: any = {},
  isChanged = false,
  options = defaultOptions,
): any {
  const _data = data || {}
  const formData = {}
  const _options = { ...defaultOptions, ...options }

  _options.defaultDataSetName ||= (fields.id || {}).dataSetName || ''
  Object.keys(fields).forEach((key) => {
    const field = fields[key]
    let value
    if (field.type === 'json' && field.properties && field.isParseProperties) {
      value = dataToFormData(_data[key], field.properties, isChanged, _options)
    } else {
      const defaultValue = typeof field.formDefaultValue === 'undefined' ? '' : field.formDefaultValue
      value = field.skip ? defaultValue : (typeof _data[key] === 'boolean' ? _data[key] : (_data[key] || defaultValue))
    }

    const { dbField, updateDbField } = _getDbFieldInfoFromField(field, _options.defaultDataSetName)

    const forcedIsChanged = _options.forceIsChangedWhenNotEmpty && value !== ''
    const includeNullValue = field.includeNullValue || false
    const _isChanged = forcedIsChanged || isChanged

    formData[key] = {
      value,
      isChanged: field.isEdit && _isChanged,
      dataSetName: field.dataSetName,
      includeNullValue,
      dbField,
      updateDbField,
    }
  })

  return formData
}

export function dataToDbData(data = {}, fields: any = {}, isAll = false, isNullishValidator = false) {
  const dbData: any = {}

  const defaultDataSetName = (fields.id || {}).dataSetName || ''
  Object.keys(fields)
    .filter((field) => fields[field].dbField && (isAll || fields[field].isEdit))
    .forEach((key) => {
      const field = fields[key]
      const { dbField } = _getDbFieldInfoFromField(field, defaultDataSetName)
      dbData[dbField] = nonNullableTypes.includes(typeof data[key]) ?
        data[key] :
        isNullishValidator ? (data[key] ?? undefined) : (data[key] || undefined)
    })

  return dbData
}

function _getDbFieldInfoFromField(field, defaultDataSetName) {
  let dbField = field.dbField
  let updateDbField = field.updateDbField
  const alias = field.trimAlias || field.dataSetAlias

  if ((field.dataSetName != defaultDataSetName) || alias) {
    dbField = `${alias || (field.dataSetName || '').toLowerCase()}_${field.dbField}`
    updateDbField = `${alias || (field.dataSetName || '').toLowerCase()}_${field.updateDbField}`
  }

  return { dbField, updateDbField }
}

export function sortArrayOfObjects(sorts) {
  const dir = []
  sorts.forEach((sort, index) => {
    dir[index] = sort.order === -1 ? -1 : 1
  })

  return (a, b) => {
    for (let index = 0; index < sorts.length; index++) {
      const sort = sorts[index]
      let direction = 0
      if (typeof a[sort.field] === 'string' && typeof b[sort.field] === 'string') {
        direction = dir[index] === -1 ?
          b[sort.field].localeCompare(a[sort.field]) :
          a[sort.field].localeCompare(b[sort.field])
      } else {
        if (a[sort.field] > b[sort.field]) direction = dir[index]
        if (a[sort.field] < b[sort.field]) direction = -(dir[index])
      }
      if (direction != 0) {
        return direction
      }
    }
    return 0
  }
}

export function filterObjectKeys(
  object,
  match,
  options = { excludeMatchedKeys: false, removeMatchFromKey: true },
) {
  return Object.keys(object).reduce((acc, key) => {
    const { excludeMatchedKeys = false, removeMatchFromKey = true } = options

    const isMatched = key.startsWith(match)
    const newKey = removeMatchFromKey ? key.replace(match, '') : key

    if (excludeMatchedKeys && isMatched) return acc
    if (!excludeMatchedKeys && !isMatched) return acc

    return {
      ...acc,
      [newKey]: object[key],
    }
  }, {})
}

export function isObjectsEqualsForKey<T, K extends keyof T>(objects: T[], key: K, value?: T[K]) {
  if (!Array.isArray(objects) || objects.length === 0) return true

  const _value = value ?? objects[0][key]

  return objects.every((object) => object[key] === _value)
}

export function getUniqueFieldValues<
  T extends Record<string, any>,
  K extends RecursiveKeys<T>,
>(array: T[], field: K): any[] {
  return [...new Set(array.map((item) => drillDownObject(item, field.toString())).filter((value) => value))]
}

function _handleGetEntityFieldInFields(field, fields, options) {
  for (const [key, value] of Object.entries<any>(fields)) {
    /**
     * if the field is equal to the key of an item in the `getFields()` or if it is equal to a `dbField`
     */
    if (key === field || value.dbField === toSnake(field)) {
      return value
    }

    // Maybe the field is a property of a json field

    if (value.type === 'json') {
      const jsonField = _handleGetEntityFieldInFields(field, value.properties ?? {}, options)

      if (jsonField) {
        return jsonField
      }
    }
  }

  const id = fields.id

  return options.fallbackToId && id ? id : undefined
}

/**
 * Get the field data of the field in the fields data list.
 * If the field is not a present in the keys, we use the dbField name (snake case)
 *
 * @param {string} field
 * @param {object[]} fields
 * @param {{fallbackToId: boolean}} [options]
 * @param {boolean} [options.fallbackToId=false] - If true, we will assume that the field is the id of the entity if
 * we do not find a field with its name
 * @return {object | undefined}
 */
export const getEntityFieldInFields = (field, fields, options = { fallbackToId: false }) => {
  const entityField = fields[toCamel(field)]

  // The field is present as a key in the fields
  if (entityField) {
    return entityField
  }

  const result = _handleGetEntityFieldInFields(field, fields, options)

  if (!result) {
    // * FOR DEBUG
    DevLogs.error(`No field found for \`${field}\` (fallbackToId : ${options.fallbackToId}).`, fields)
  }

  return result
}

export function toArray<T>(data: T | T[]): T[] {
  if (!data) return []

  if (Array.isArray(data)) return data

  return [data]
}

export function isUrl(url: string) {
  if (!url) return false
  const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  const res = url.match(regex)
  return (res !== null)
}

export function getValidUrl(url: string) {
  if (!url) return null

  const _url = url.trim()
  return !_url?.includes('http://') && !_url?.includes('https://') ? `https://${_url}` : _url
}

export function getKeyUniqueValues<T>(array: T[], key: keyof T) {
  return [...new Set(
    array.reduce((acc, item) => {
      if (item[key]) {
        acc.push(item[key])
      }

      return acc
    }, []),
  )]
}

/**
 * Merge the final form data with the initial form data.
 * If a field has been changed manually, this function will use the
 * initial form data field to prevent unwanted updates.
 */

export function mergeUnchangedFormData(initialFormData, finalFormData) {
  return Object.keys(finalFormData).reduce((acc, field) => {
    if (initialFormData[field]?.isChanged === true) {
      acc[field] = initialFormData[field]
    } else {
      acc[field] = finalFormData[field]
    }
    return acc
  }, {})
}

export function updateLineItemsFormData(
  lineItemsForm: LineItemsForm,
  type: 'insertions' | 'updates',
  data: { id: string, field: string, value: any },
) {
  const oldIsChanged = lineItemsForm[type][data.id][data.field].isChanged
  const isChanged = data.value != lineItemsForm[type][data.id][data.field].value
  lineItemsForm[type][data.id][data.field] = {
    ...lineItemsForm[type][data.id][data.field],
    value: data.value,
    isChanged: oldIsChanged || isChanged,
  }
  if (type === 'updates') {
    const oldIsGlobalChanged = lineItemsForm[type][data.id].isGlobalChange
    lineItemsForm[type][data.id].isGlobalChange = oldIsGlobalChanged || isChanged
  }
}
